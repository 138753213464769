import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fluid--modal"
export default class extends Controller {
  connect() {
    this.escapeListener = (event) => {
      if (event.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", this.escapeListener);
  }

  disconnect() {
    document.removeEventListener("keydown", this.escapeListener);
  }

  show(event) {
    const modalId = event.currentTarget.getAttribute("data-modal-target");
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.classList.remove("hidden");
      this.dispatch("opened", {});
      const fieldFocus = modal.getAttribute("data-field-focus");
      modal.querySelector(`${fieldFocus}`)?.focus();
    }
  }

  close(event) {
    const modal = event
      ? event.currentTarget.closest(".fluid-modal-container")
      : document.querySelector(".fluid-modal-container:not(.hidden)");
    if (modal) {
      modal.classList.add("hidden");
      this.dispatch("closed", {});
    }
  }
}
