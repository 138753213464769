// app/javascript/controllers/projects/campaigns/matchallenge_controller.js
import { Controller } from "@hotwired/stimulus";
import Masonry from "masonry-layout";

// Connects to data-controller="projects--campaigns--matchallenge"
export default class extends Controller {
  static targets = ["item", "button"];

  initialize() {
    this.index = 0;
    this.showItems();
  }

  connect() {
    // Initialize Masonry and store the instance
    if (window.innerWidth > 768) { // Only initialize Masonry on desktop/tablet
      setTimeout(() => {
        this.msnry = new Masonry(this.element.querySelector(".matchallenge-grid"), {
          itemSelector: ".matchallenge-item",
          columnWidth: ".matchallenge-item",
          percentPosition: true
        });
      }, 500);
    }
  }

  showItems() {
    this.itemTargets.forEach((item, index) => {
      item.classList.toggle("hidden", index >= this.index + 9);
    });
    if (this.index + 9 >= this.itemTargets.length) {
      if (this.buttonTargets.length > 0) {
        this.buttonTarget.classList.add("hidden");
      }
    }

    // Re-layout Masonry after showing items
    if (this.msnry) {
      this.msnry.layout();
    }
  }

  loadMore() {
    this.index += 9;
    this.showItems();
  }
}

