/* eslint no-alert: 0 */
/* eslint no-console: 0 */

import { Controller } from "@hotwired/stimulus";

// This controller is just for testing stimulus on your page.
// Put this HTML on your page to test it:

/*
  div[data-controller="debug-stimulus"]
    input[data-debug-stimulus-target="name" type="text"]
    button[data-action="click->debug-stimulus#greet"] Greet
*/

// If the controller isn't working, try adding your page's route to the `allow_fluid_and_vite` conditional in app/views/layouts/application.html.slim

export default class extends Controller {
  static targets = ["name"];

  connect() {
    console.log("Hello, Stimulus!");
  }

  greet() {
    alert(`Hello, ${this.name}!`);
  }

  get name() {
    return this.nameTarget.value || "Stimulus";
  }
}