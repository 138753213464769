import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--settings--form-fields--view-forms-panel"
export default class extends Controller {
  static targets = ["searchInput", "resetButton"];

  connect() {
    this.toggleResetButton(); // Initialize the reset button visibility
  }

  search(event) {
    event.preventDefault();
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();
    const rows = this.element.querySelectorAll("#all-forms-table tr");

    this.toggleResetButton();
    this.filterRows(rows, searchTerm);
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.toggleResetButton();
    this.searchInputTarget.focus();

    const rows = this.element.querySelectorAll("#all-forms-table tr");
    this.filterRows(rows, ""); // Reset all rows
  }

  handleInput() {
    this.toggleResetButton();
  }

  toggleResetButton() {
    this.resetButtonTarget.classList.toggle(
      "hidden",
      this.searchInputTarget.value.trim() === "",
    );
  }

  filterRows(rows, searchTerm) {
    rows.forEach((row) => {
      row.classList.toggle(
        "hidden",
        !row.innerText.toLowerCase().includes(searchTerm),
      );
    });
  }
}
