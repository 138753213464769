import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fluid--panel"
export default class extends Controller {
  static targets = ["content"];

  static values = {
    expanded: { type: Boolean, default: false }
  };

  connect() {
    this.expandedValue = this.element.dataset.fluidPanelExpanded === "true";
  }

  expandedValueChanged() {
    this.element.setAttribute("aria-expanded", this.expandedValue);
    this.element.querySelector(".chevron-right").classList.toggle("hidden");
    this.element.querySelector(".chevron-down").classList.toggle("hidden");
    this.contentTarget.classList.toggle("hidden");
  }

  toggle(event) {
    const panel = this.targetPanel(event);
    panel.expandedValue = !panel.expandedValue;
  }

  collapse(event) {
    const panel = this.targetPanel(event);
    panel.expandedValue = false;
  }

  expand(event) {
    const panel = this.targetPanel(event);
    panel.expandedValue = true;
  }

  targetPanel(event) {
    const targetId = event.currentTarget.getAttribute("data-panel-target");
    if (!targetId) return this;

    const target = document.getElementById(targetId);
    return this.application.getControllerForElementAndIdentifier(target, this.identifier);
  }
}
