import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='fluid--radio-toggle'
export default class extends Controller {
  static targets = [
    "optionOneForm", "optionOneInput", "optionOneValue",
    "optionTwoForm", "optionTwoInput", "optionTwoValue"
  ];

  toggle(event) {
    const selectedValue = event.target.value;

    this.toggleOption("One", selectedValue);
    this.toggleOption("Two", selectedValue);
  }

  toggleOption(optionNum, selectedValue) {
    if (!this[`hasOption${optionNum}ValueTarget`]) return;
    if (!this[`hasOption${optionNum}FormTarget`]) return;
    if (!this[`hasOption${optionNum}InputTarget`]) return;

    const optionValue = this[`option${optionNum}ValueTarget`].value;
    this[`option${optionNum}FormTarget`].style.display = optionValue === selectedValue ? "block" : "none";
    this[`option${optionNum}InputTarget`].required = optionValue === selectedValue;
  }
}
