import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="categories--admin--gift-officer--ai-profile"
export default class extends Controller {
  static targets = ['aiProfileForm']

  connect() {
  }

  submitAiProfileForm() {
    this.aiProfileFormTarget.requestSubmit();
  }
}
