import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--events--edit--promo-codes"
export default class extends Controller {
  static targets = ["promoCodesForm", "discountTypeInput", "discountAmountInput", "discountAmountLabel", "limitInputContainer", "limitInput"];

  connect() {
  }

  handleDiscountTypeChange() {
    const discountType = this.discountTypeInputTarget.value;
    switch (discountType) {
      case "percentage":
        this.discountAmountLabelTarget.textContent = "Discount Percent";
        this.discountAmountInputTarget.placeholder = "%";
        this.discountAmountInputTarget.setAttribute("max", 100);
        break;
      default:
        this.discountAmountLabelTarget.textContent = "Discount Amount";
        this.discountAmountInputTarget.placeholder = "$";
        this.discountAmountInputTarget.removeAttribute("max");
        break;
    }
  }

  showForm(event) {
    event.preventDefault();
    this.promoCodesFormTarget.classList.remove("hidden");
  }

  handleToggleLimits(event) {
    const isChecked = event.target.checked;

    if (isChecked === true) {
      this.limitInputContainerTarget.classList.remove("hidden");
      this.limitInputTarget.required = true;
    } else {
      this.limitInputContainerTarget.classList.add("hidden");
      this.limitInputTarget.required = false;
      this.limitInputTarget.value = "";
    }
  }
}