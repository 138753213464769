import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "searchInput",
    "resetButton",
    "selectAllCheckbox",
    "fieldCheckbox",
    "applyButton",
    "updateAllFormsOption",
    "fieldEditForm",
    "updateSelectFormsOption",
    "updateFormsTableContainer",
    "updateFormsSearchContainer",
    "applyChangesContainer",
    "confirmationAlert",
    "confirmationText",
    "titleField",
    "inputTypeField",
    "labelField",
    "labelFieldLabel",
    "labelFieldInput",
    "placeholderField",
    "placeholderFieldLabel",
    "placeholderFieldInput",
    "helpTextField",
    "helpTextFieldInput",
    "requiredField",
    "requiredFieldInput",
    "nextButton",
    "backButton",
    "compressField",
    "compressFieldCheckbox",
    "expandField",
    "expandFieldCheckbox",
  ];

  connect() {
    this.field = {};
    this.selectedForms = [];
  }

  load(event) {
    this.field = event.detail.field;
    this.setPanelHeader();
    this.loadFieldFormData();
    this.loadTableData();
    this.toggleButtons();
    this.backButtonTarget.classList.add("hidden");
    this.nextButtonTarget.classList.add("hidden");
    this.updateAllFormsOptionTarget.checked = true;
    this.changeUpdateForms();

    // Toggle compress/expand field checkboxes
    if (this.field.compressible === true && this.field.group === "name") {
      this.compressFieldTarget.classList.remove("hidden");
      this.expandFieldTarget.classList.add("hidden");
    } else if (this.field.expandable === true && this.field.group === "name") {
      this.expandFieldTarget.classList.remove("hidden");
      this.compressFieldTarget.classList.add("hidden");
    } else {
      this.compressFieldTarget.classList.add("hidden");
      this.expandFieldTarget.classList.add("hidden");
    }
  }

  unload() {
    this.field = {};
    this.selectedForms = [];
    this.searchInputTarget.value = "";
    this.toggleButtons();
    this.back();
    this.compressFieldTarget.classList.add("hidden");
    this.expandFieldTarget.classList.add("hidden");
    this.compressFieldCheckboxTarget.checked = false;
    this.expandFieldCheckboxTarget.checked = false;
  }

  setPanelHeader() {
    document.querySelector(
      "#edit-field-panel .fluid-modal-subtitle",
    ).textContent = this.field.title;

    document.querySelector(
      "#edit-field-panel .field-location"
    ).textContent = this.field.location;
  }

  loadFieldFormData() {
    this.titleFieldTarget.textContent = this.field.title;
    this.inputTypeFieldTarget.textContent = this.field.input_type;

    switch (this.field.input_type) {
      case "text":
        this.helpTextFieldInputTarget.value = "";
        this.labelFieldInputTarget.required = true;
        this.labelFieldInputTarget.value = "";
        this.labelFieldLabelTarget.classList.add("required");
        this.placeholderFieldInputTarget.value = "";
        this.placeholderFieldTarget.classList.remove("hidden");
        this.placeholderFieldTarget.required = false;
        this.requiredFieldInputTarget.value = "";
        break;
      case "checkbox":
        this.labelFieldInputTarget.required = true;
        this.labelFieldLabelTarget.classList.add("required");
        this.placeholderFieldTarget.classList.add("hidden");
        this.placeholderFieldTarget.required = false;
        break;
      case "radio":
        this.labelFieldInputTarget.required = true;
        this.labelFieldLabelTarget.classList.add("required");
        this.placeholderFieldTarget.classList.add("hidden");
        this.placeholderFieldTarget.required = false;
        break;
      case "select":
        this.helpTextFieldTarget.classList.remove("hidden");
        this.labelFieldInputTarget.required = true;
        this.labelFieldLabelTarget.classList.add("required");
        this.placeholderFieldTarget.classList.remove("hidden");
        this.placeholderFieldTarget.required = false;
        this.requiredFieldTarget.classList.remove("hidden");
        break;
      case "section_divider":
        this.helpTextFieldTarget.classList.add("hidden");
        this.labelFieldInputTarget.required = false;
        this.labelFieldInputTarget.value = "";
        this.labelFieldLabelTarget.classList.remove("required");
        this.labelFieldTarget.classList.add("hidden");
        this.placeholderFieldLabelTarget.classList.add("required");
        this.placeholderFieldTarget.required = true;
        this.requiredFieldTarget.classList.add("hidden");
        break;
      default:
        this.labelFieldInputTarget.required = true;
        this.labelFieldLabelTarget.classList.add("required");
        this.placeholderFieldLabelTarget.classList.remove("required");
        this.placeholderFieldTarget.required = false;
        break;
    }
  }

  loadTableData() {
    const table = document.getElementById("edit-forms-table");
    table.innerHTML = "";
    this.field.projects.forEach((project) => {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td class="text-center">
          <input type="checkbox" name="project_ids[]" value="${project.id}" aria-label="Select ${project.name}"
           data-action="click->categories--admin--settings--form-fields--edit-field-panel#selectForm"
           data-categories--admin--settings--form-fields--edit-field-panel-target="fieldCheckbox">
        </td>
        <td><a href="${project.url}" target="_blank">${project.name}</a></td>
        <td>${project.location === "pre" ? "pre-form" : "post-form"}</td>
        <td>${project.state}</td>
      `;
      table.appendChild(row);
    });

    this.changeUpdateForms();
  }

  search(event) {
    event.preventDefault();
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();
    const rows = this.element.querySelectorAll("#edit-forms-table tr");

    this.toggleResetButton();

    rows.forEach((row) => {
      const rowText = row.innerText.toLowerCase();
      row.classList.toggle("hidden", !rowText.includes(searchTerm));
    });
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.searchInputTarget.focus();
    this.toggleResetButton();
    this.search(event);
  }

  toggleResetButton() {
    this.resetButtonTarget.classList.toggle(
      "hidden",
      this.searchInputTarget.value.trim() === "",
    );
  }

  changeUpdateForms() {
    if (this.updateAllFormsOptionTarget.checked) {
      this.updateFormsTableContainerTarget.classList.add("hidden");
      this.updateFormsSearchContainerTarget.classList.add("hidden");
      this.selectedForms = this.fieldCheckboxTargets.map(
        (checkbox) => checkbox.value,
      );
      this.fieldCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = true;
      });

      this.applyButtonTarget.classList.remove("hidden");
      this.nextButtonTarget.classList.add("hidden");
      this.toggleButtons();
    } else if (this.updateSelectFormsOptionTarget.checked) {
      this.applyButtonTarget.classList.add("hidden");
      this.nextButtonTarget.classList.remove("hidden");
      this.selectedForms = [];
      this.fieldCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.toggleButtons();
    }
  }

  next() {
    this.updateFormsTableContainerTarget.classList.remove("hidden");
    this.updateFormsSearchContainerTarget.classList.remove("hidden");
    this.applyButtonTarget.classList.remove("hidden");
    this.backButtonTarget.classList.remove("hidden");
    this.nextButtonTarget.classList.add("hidden");
    this.fieldEditFormTarget.classList.add("hidden");
    this.applyChangesContainerTarget.classList.add("hidden");
  }

  back() {
    this.fieldEditFormTarget.classList.remove("hidden");
    this.applyChangesContainerTarget.classList.remove("hidden");
    this.applyButtonTarget.classList.remove("hidden");
    this.updateFormsTableContainerTarget.classList.add("hidden");
    this.updateFormsSearchContainerTarget.classList.add("hidden");
    this.nextButtonTarget.classList.add("hidden");
    this.backButtonTarget.classList.add("hidden");
    this.updateAllFormsOptionTarget.checked = true;
    this.changeUpdateForms();
  }

  selectAllForms(event) {
    const { checked } = event.target;
    this.fieldCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
    this.selectedForms = checked
      ? this.fieldCheckboxTargets.map((checkbox) => checkbox.value)
      : [];
    this.toggleButtons();
  }

  selectForm(event) {
    const checkbox = event.target;
    const selectedFormsSet = new Set(this.selectedForms);

    if (checkbox.checked) {
      selectedFormsSet.add(checkbox.value);
    } else {
      selectedFormsSet.delete(checkbox.value);
    }

    this.selectedForms = Array.from(selectedFormsSet);
    this.updateSelectAllCheckbox();
    this.toggleButtons();
  }

  updateSelectAllCheckbox() {
    const allChecked = this.fieldCheckboxTargets.every(
      (checkbox) => checkbox.checked,
    );
    this.selectAllCheckboxTarget.checked = allChecked;
  }

  toggleButtons() {
    if (this.selectedForms.length > 0) {
      this.applyButtonTarget.removeAttribute("disabled");
      this.applyButtonTarget.textContent = `Apply to ${this.selectedForms.length} Form(s)`;
    } else {
      this.applyButtonTarget.setAttribute("disabled", true);
      this.applyButtonTarget.textContent = "Apply to X Forms";
    }
  }

  cancel(event) {
    event.preventDefault();
    this.field = {};
    this.selectedForms = [];
    this.searchInputTarget.value = "";
    this.toggleButtons();
    this.back();
    this.compressFieldTarget.classList.add("hidden");
    this.expandFieldTarget.classList.add("hidden");
    this.compressFieldCheckboxTarget.checked = false;
    this.expandFieldCheckboxTarget.checked = false;

    document.querySelectorAll(".fluid-modal-container").forEach((modal) => {
      modal.classList.add("hidden");
    });
  }

  showConfirmation() {
    this.confirmationTextTarget.innerHTML = `Updating the field <strong>${this.field.title}</strong> will affect <strong>${this.selectedForms.length}</strong> giving form(s).`;
    this.confirmationAlertTarget.classList.remove("hidden");
  }

  closeConfirmation() {
    this.confirmationAlertTarget.classList.add("hidden");
  }

  fieldAttributes() {
    return {
      label: this.labelFieldInputTarget.value,
      placeholder: this.placeholderFieldInputTarget.value,
      help_text: this.helpTextFieldInputTarget.value,
      required: this.requiredFieldInputTarget.value,
      compress: this.compressFieldCheckboxTarget.checked,
      expand: this.expandFieldCheckboxTarget.checked,
    };
  }

  updateField(event) {
    const { schoolSlug } = event.target.dataset;

    fetch(
      `/schools/${schoolSlug}/admin/settings/form-fields/${this.field.ids[0]}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
            .content,
        },
        body: JSON.stringify({
          field: {
            addtnl_field_ids: this.field.ids,
            field_attributes: this.fieldAttributes(),
            selected_forms: this.selectedForms,
          },
        }),
      },
    )
      .then((response) => response.json())
      .then(() => {
        this.cancel(event);
        window.location.reload();
      });
  }
}
