import { Controller } from "@hotwired/stimulus";

// example usage:
// data-controller - required
// data-scroll-to - required
// data-scroll-offset - optional
// link_to some_app_path(), data: { controller: "smooth--scroll", scroll_to: "#some_element_to_scroll_to_on_click", scroll_offset="100" }
export default class extends Controller {
  connect() {
    this.smoothScroll();
  }

  smoothScroll() {
    document.addEventListener("click", (event) => {
      const targetElement = document.querySelector(`${event.target.getAttribute("data-scroll-to")}`);
      if (targetElement) {
        const scrollOffset = event.target.getAttribute("data-scroll-offset") || 0;
        window.scrollTo({
          top: targetElement.getBoundingClientRect().top + window.scrollY - scrollOffset,
          behavior: "smooth"
        });
      }
    });
  }
}