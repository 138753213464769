import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fluid--toggle-radio"
export default class extends Controller {
  static targets = ["radio", "container"];

  connect() {
    this.updateStyles();
  }

  updateStyles() {
    this.radioTargets.forEach((radio, index) => {
      const container = this.containerTargets[index];
      if (radio.checked) {
        container.classList.remove("border-gray-500");
        container.classList.add("bg-gray-100", "border-primary-blue");
      } else {
        container.classList.add("border-gray-500");
        container.classList.remove("bg-gray-100", "border-primary-blue");
      }
    });
  }

  select() {
    this.updateStyles();
  }
}
