import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fluid-table"
export default class extends Controller {
  static targets = ["row"];

  connect() {
    this.rowTargets.forEach(row => {
      // Find all focusable elements within the row
      const focusableElements = row.querySelectorAll("input, select, textarea, button, a");
      focusableElements.forEach(element => {
        // Add blur event listener to each focusable element
        element.addEventListener("blur", this.blur.bind(this));
      });
    });
  }

  highlight(event) {
    // First, remove highlight from all rows
    this.rowTargets.forEach(row => row.classList.remove("bg-accent-blue"));

    // Add highlight to the current row
    event.currentTarget.classList.add("bg-accent-blue");
  }

  blur(event) {
    // Delay removing the highlight to allow for focus to possibly shift within the same row
    setTimeout(() => {
      if (event.currentTarget) {
        if (!event.currentTarget.contains(document.activeElement)) {
          this.rowTargets.forEach(row => row.classList.remove("bg-accent-blue"));
        }
      }
    }, 1);
  }
}
