import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--pages--campaigns-tiered-list"
export default class extends Controller {
  static targets = ["campaignRow", "expandCarat", "collapseCarat"];

  expand(event) {
    event.preventDefault();
    const relatedSet = new Set([event.currentTarget.dataset.id]);

    this.campaignRowTargets.forEach(el => {
      if (relatedSet.has(el.dataset.parent)) {
        relatedSet.add(el.dataset.id);
        el.classList.remove("hidden");
      }
    });

    this.expandCaratTargets.forEach(el => {
      if (relatedSet.has(el.dataset.id)) {
        el.classList.add("hidden");
      }
    });

    this.collapseCaratTargets.forEach(el => {
      if (relatedSet.has(el.dataset.id)) {
        el.classList.remove("hidden");
      }
    });
  }

  collapse(event) {
    event.preventDefault();
    const relatedSet = new Set([event.currentTarget.dataset.id]);

    this.campaignRowTargets.forEach(el => {
      if (relatedSet.has(el.dataset.parent)) {
        relatedSet.add(el.dataset.id);
        el.classList.add("hidden");
      }
    });

    this.expandCaratTargets.forEach(el => {
      if (relatedSet.has(el.dataset.id)) {
        el.classList.remove("hidden");
      }
    });

    this.collapseCaratTargets.forEach(el => {
      if (relatedSet.has(el.dataset.id)) {
        el.classList.add("hidden");
      }
    });
  }
}
