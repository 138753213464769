import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--settings--form-fields"
export default class extends Controller {
  static targets = ["searchInput", "resetButton"];

  connect() {
    this.toggleResetButton(); // Initialize the reset button visibility
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.toggleResetButton();
    this.searchInputTarget.form.submit();
  }

  handleInput() {
    this.toggleResetButton();
  }

  toggleResetButton() {
    if (this.searchInputTarget.value.trim() === "") {
      this.resetButtonTarget.classList.add("hidden");
    } else {
      this.resetButtonTarget.classList.remove("hidden");
    }
  }

  populateFormsTable(event) {
    event.preventDefault();

    // Load projects from event target data-projects attribute
    const field = JSON.parse(event.target.dataset.field);
    const table = document.getElementById("all-forms-table");

    // Set the Panel title
    document.querySelector("#view-forms-panel .fluid-modal-title").textContent =
      field.title;

    // Clear any existing table rows
    table.innerHTML = "";

    // Populate the table with the projects
    field.projects.forEach((project) => {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td><a href='${project.url}' target='_blank'>${project.name}</a></td>
        <td>${project.state}</td>
      `;
      table.appendChild(row);
    });
  }

  // Select a field from the table and dispatch the fieldSelected event to the panels
  selectField(event) {
    const field = JSON.parse(event.currentTarget.dataset.field);
    this.dispatch("fieldSelected", { detail: { field } });
  }
}
