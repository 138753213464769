import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fluid--accordion"
export default class extends Controller {
  static targets = ["clickable", "hideable"];

  toggle(event) {
    const selectedTarget = event.currentTarget;

    if (!selectedTarget.dataset.index) return;

    this.hideableTargets.forEach(e => {
      if (e.dataset.index === selectedTarget.dataset.index) {
        e.classList.remove("hidden");
      } else {
        e.classList.add("hidden");
      }
    });

    this.clickableTargets.forEach(e => {
      if (e === selectedTarget) {
        e.classList.add(e.dataset.selectedClasses);
        e.classList.remove(e.dataset.hiddenClasses);
      } else {
        e.classList.remove(e.dataset.selectedClasses);
        e.classList.add(e.dataset.hiddenClasses);
      }
    });
  }
}