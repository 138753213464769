import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--administrators--all-object-access"
export default class extends Controller {
  static targets = ["campaignAccessForm", "campaignSection", "givingFormAccessForm", "givingFormSection", "eventAccessForm", "eventSection"];

  connect() {
  }

  submitCampaignAccessForm() {
    this.campaignAccessFormTarget.requestSubmit();
    this.campaignSectionTargets.forEach((el) => {
      el.classList.toggle("hidden");
    });
  }

  submitGivingFormAccessForm() {
    this.givingFormAccessFormTarget.requestSubmit();
    this.givingFormSectionTargets.forEach((el) => {
      el.classList.toggle("hidden");
    });
  }

  submitEventAccessForm() {
    this.eventAccessFormTarget.requestSubmit();
    this.eventSectionTargets.forEach((el) => {
      el.classList.toggle("hidden");
    });
  }
}
