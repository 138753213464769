import { Controller } from "@hotwired/stimulus"
import debounce from "debounce"

// Connects to data-controller="fluid--listbox"
export default class extends Controller {
  static targets = ['listItem', 'input', 'clearSearchButton']

  initialize() {
    this.search = debounce(this.search.bind(this), 300)
  }

  connect() {
    this.clearSearchButtonTarget.style.display = 'none'
  }

  search() {
    const searchTerm = this.inputTarget.value.toLowerCase()

    if (searchTerm.length) {
      this.clearSearchButtonTarget.style.display = 'block'
    } else {
      this.clearSearchButtonTarget.style.display = 'none'
    }

    this.listItemTargets.forEach((item) => {
      const text = item.textContent.toLowerCase()
      if (text.includes(searchTerm)) {
        item.style.display = 'block'
      } else {
        item.style.display = 'none'
      }
    })

    this.inputTarget.focus()
  }

  clearSearch() {
    this.inputTarget.value = ''
    this.search()
  }
}
