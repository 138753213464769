// app/javascript/controllers/masonry_controller.js
import { Controller } from "@hotwired/stimulus";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";  // Import the imagesLoaded library

// Connects to data-controller="masonry"
export default class extends Controller {
  static targets = ["grid"];

  connect() {
    this.itemSelector = this.data.get("itemSelector") || ".masonry-item";
    this.columnWidth = this.data.get("columnWidth") || this.itemSelector;

    this.initializeMasonry();
    window.addEventListener("resize", this.layout.bind(this));
  }

  initializeMasonry() {
    if (window.innerWidth > 768) { // Only initialize Masonry on desktop/tablet
      setTimeout(() => {
        this.msnry = new Masonry(this.gridTarget, {
          itemSelector: this.itemSelector,
          columnWidth: this.columnWidth,
          percentPosition: true
        });
      }, 500);

      // Re-layout Masonry when all images have loaded
      if (this.msnry) {
        imagesLoaded(this.gridTarget, () => {
          this.msnry.layout();
        });
      }
    }
  }

  layout() {
    if (this.msnry) {
      this.msnry.layout();
    }
  }

  disconnect() {
    if (this.msnry) {
      this.msnry.destroy();
    }
    window.removeEventListener("resize", this.layout.bind(this));
  }
}
