import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--campaigns--matchallenges-competing-designations"
export default class extends Controller {
  static targets = ["competitor", "optionsList", "addedList"];

  static outlets = ["categories--admin--campaigns--matchallenges--competing-designations"];

  resetOtherLists(_e) {
    this.categoriesAdminCampaignsMatchallengesCompetingDesignationsOutlets.forEach((outlet) => {
      outlet.removeAll();
    });
  }

  addCompetitor({ params }) {
    const competitorId = params.id.toString();

    this.competitorTargets.some((competitor) => {
      const competitorIdAttr = competitor.dataset.id;

      if (competitorId === competitorIdAttr) {
        this.transferCompetitorToAddedList(competitor);
        return true;
      }

      return false;
    });
  }

  addedCompetitorIds() {
    return Array.from(this.addedListTarget.children).map((competitor) => competitor.firstElementChild.dataset.id);
  }

  initializeSelectedCompetitors({ params }) {
    this.removeAll();
    params.ids.forEach((id) => {
      this.addCompetitor({ params: { id } });
    });
  }

  addAll(_e) {
    const addedIds = this.addedCompetitorIds();
    this.competitorTargets.forEach((competitor) => {
      if (!addedIds.includes(competitor.dataset.id)) {
        this.transferCompetitorToAddedList(competitor);
      }
    });
  }

  transferCompetitorToAddedList(originalCompetitor) {
    const clone = originalCompetitor.parentNode.cloneNode(true);
    const inputs = clone.getElementsByTagName("input");
    Array.from(inputs).forEach((input) => { input.disabled = false; });
    this.addedListTarget.appendChild(clone);
    this.toggleCompetitorButtons(clone);
    originalCompetitor.style.display = "none";
  }

  displayAvailableCompetitor(competitorId) {
    const competitor = this.competitorTargets.find((comp) => comp.dataset.id === competitorId);
    if (competitor) competitor.style.display = "flex";
  }

  removeCompetitor({ params }) {
    const competitorId = params.id.toString();

    Array.from(this.addedListTarget.children).some((competitor) => {
      const competitorIdAttr = competitor.firstElementChild.dataset.id;

      if (competitorId === competitorIdAttr) {
        competitor.remove();
        this.displayAvailableCompetitor(competitorId);
        return true;
      }

      return false;
    });
  }

  removeAll() {
    Array.from(this.addedListTarget.children).forEach((competitor) => {
      competitor.remove();
      this.displayAvailableCompetitor(competitor.firstElementChild.dataset.id);
    });
  }

  toggleCompetitorButtons(competitor) {
    const addButton = competitor.getElementsByClassName("add-button")[0];
    if (addButton) {
      addButton.style.display = addButton.style.display === "none" ? "block" : "none";
    }
    const removeButton = competitor.getElementsByClassName("remove-button")[0];
    if (removeButton) {
      removeButton.style.display = removeButton.style.display === "none" ? "block" : "none";
    }
  }
}
