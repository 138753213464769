import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "searchInput",
    "resetButton",
    "selectAllCheckbox",
    "fieldCheckbox",
    "applyButton",
    "removeFromAllFormsOption",
    "removeFromSelectFormsOption",
    "removeFromFormsTableContainer",
    "removeFromFormsSearchContainer",
    "confirmationAlert",
    "confirmationText",
  ];

  connect() {
    this.field = {};
    this.selectedForms = [];
    this.initializeForm();
  }

  load(event) {
    this.field = event.detail.field;
    this.setPanelHeader();
    this.loadTableData();
    this.initializeForm();
  }

  unload() {
    this.field = {};
    this.selectedForms = [];
    this.initializeForm();
  }

  initializeForm() {
    this.toggleButtons();
    this.removeFromAllFormsOptionTarget.checked = true;
    this.changeRemoveFromForms();
  }

  setPanelHeader() {
    document.querySelector(
      "#remove-field-panel .fluid-modal-subtitle",
    ).textContent = this.field.title;
    document.querySelector(
      "#remove-field-panel .field-location",
    ).textContent = this.field.location;
  }

  search(event) {
    event.preventDefault();

    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();
    const rows = this.element.querySelectorAll("#remove-from-forms-table tr");

    this.toggleResetButton();

    rows.forEach((row) => {
      const rowText = row.innerText.toLowerCase();
      row.classList.toggle("hidden", !rowText.includes(searchTerm));
    });
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.searchInputTarget.focus();
    this.toggleResetButton();
    this.search(event);
  }

  toggleResetButton() {
    this.resetButtonTarget.classList.toggle(
      "hidden",
      this.searchInputTarget.value.trim() === "",
    );
  }

  loadTableData() {
    const table = document.getElementById("remove-from-forms-table");
    table.innerHTML = "";

    this.field.projects.forEach((project) => {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td class="text-center">
          <input type="checkbox" name="project_ids[]" value="${project.id}" aria-label="Select ${project.name}"
            data-action="click->categories--admin--settings--form-fields--remove-field-panel#selectForm"
            data-categories--admin--settings--form-fields--remove-field-panel-target="fieldCheckbox">
        </td>
        <td><a href="${project.url}" target="_blank">${project.name}</a></td>
        <td>${project.location === "pre" ? "pre-form" : "post-form"}</td>
        <td>${project.state}</td>
      `;
      table.appendChild(row);
    });

    this.changeRemoveFromForms();
  }

  changeRemoveFromForms() {
    const isRemovingAll = this.removeFromAllFormsOptionTarget.checked;
    this.toggleFormsTableVisibility(!isRemovingAll);

    if (isRemovingAll) {
      this.selectedForms = this.fieldCheckboxTargets.map(
        (checkbox) => checkbox.value,
      );
      this.checkAllCheckboxes(true);
    } else {
      this.selectedForms = [];
      this.checkAllCheckboxes(false);
    }

    this.toggleButtons();
  }

  toggleFormsTableVisibility(isVisible) {
    this.removeFromFormsTableContainerTarget.classList.toggle(
      "hidden",
      !isVisible,
    );
    this.removeFromFormsSearchContainerTarget.classList.toggle(
      "hidden",
      !isVisible,
    );
  }

  checkAllCheckboxes(checked) {
    this.fieldCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  selectAllForms(event) {
    const {checked} = event.target;
    this.checkAllCheckboxes(checked);

    this.selectedForms = checked
      ? this.fieldCheckboxTargets.map((checkbox) => checkbox.value)
      : [];
    this.toggleButtons();
  }

  selectForm(event) {
    const checkbox = event.target;
    const selectedFormsSet = new Set(this.selectedForms);

    if (checkbox.checked) {
      selectedFormsSet.add(checkbox.value);
    } else {
      selectedFormsSet.delete(checkbox.value);
    }

    this.selectedForms = Array.from(selectedFormsSet);
    this.updateSelectAllCheckbox();
    this.toggleButtons();
  }

  updateSelectAllCheckbox() {
    const allChecked = this.fieldCheckboxTargets.every(
      (checkbox) => checkbox.checked,
    );
    this.selectAllCheckboxTarget.checked = allChecked;
  }

  toggleButtons() {
    const hasSelectedForms = this.selectedForms.length > 0;
    this.applyButtonTarget.toggleAttribute("disabled", !hasSelectedForms);
    this.applyButtonTarget.textContent = hasSelectedForms
      ? `Apply to ${this.selectedForms.length} Form(s)`
      : "Apply to X Forms";
  }

  cancel() {
    this.unload();
    this.closeModals();
  }

  closeModals() {
    document.querySelectorAll(".fluid-modal-container").forEach((modal) => {
      modal.classList.add("hidden");
    });
  }

  showConfirmation() {
    this.confirmationTextTarget.innerHTML = `Removing the field <strong>${this.field.title}</strong> will affect <strong>${this.selectedForms.length}</strong> giving form(s).`;
    this.confirmationAlertTarget.classList.remove("hidden");
  }

  closeConfirmation() {
    this.confirmationAlertTarget.classList.add("hidden");
  }

  removeField(event) {
    const { schoolSlug } = event.target.dataset;

    fetch(`/schools/${schoolSlug}/admin/settings/form-fields/${this.field.ids[0]}`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({
        field: {
          addtnl_field_ids: this.field.ids,
          selected_forms: this.selectedForms,
        }
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.cancel(event);
        window.location.reload();
      });
  }
}
