import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image"
export default class extends Controller {
  static targets = ["altTextInput", "imageUploadInput", "overlayColor", "overlayOpacity", "imagePreview", "imageOverlay", "imageUploadButton", "imageFileName"];

  connect() {
    this.updateAltTextAndImage();
    this.setupColorAndOpacity();
    this.setDefaultImage();
  }

  updateAltTextAndImage() {
    this.updateAltText();
    this.updatePreviewImage();
  }

  updateAltText() {
    if (!(this.hasAltTextInputTarget && this.hasImageUploadInputTarget)) return;

    const altText = this.altTextInputTarget;
    const imageUploadInput = this.imageUploadInputTarget;

    if (imageUploadInput.files.length > 0) {
      altText.setAttribute("required", true);
      document.querySelector(`[for='${altText.id}']`).classList.add("required");
    }
  }

  uploadImage() {
    if (!(this.hasImageUploadInputTarget)) return;

    const imageUploadInput = this.imageUploadInputTarget;
    imageUploadInput.click();
  }

  updatePreviewImage() {
    if (!(this.hasImageUploadInputTarget && this.hasImagePreviewTarget && this.hasImageFileNameTarget)) return;

    const imageUploadInput = this.imageUploadInputTarget;
    const imagePreview = this.imagePreviewTarget;
    const imageFileName = this.imageFileNameTarget;

    if (imageUploadInput.files && imageUploadInput.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        imagePreview.style["background-image"] = `url(${e.target.result})`;
        imageFileName.textContent = imageUploadInput.files[0].name;
      };

      reader.readAsDataURL(imageUploadInput.files[0]);
    } else {
      imageFileName.textContent = "No File Chosen";
      imagePreview.style["background-image"] = this.defaultImage;
    }
  }

  setDefaultImage() {
    if (!(this.hasImagePreviewTarget)) return;

    this.defaultImage = this.imagePreviewTarget.style["background-image"];
  }

  updateOverlayOpacity() {
    if (!(this.hasImageOverlayTarget && this.hasOverlayOpacityTarget)) return;

    const overlay = this.imageOverlayTarget;
    const overlayOpacity = this.overlayOpacityTarget;
    overlay.style.opacity = overlayOpacity.value;
  }

  setupColorAndOpacity() {
    if (!this.hasImageOverlayTarget && this.hasOverlayOpacityTarget && this.hasOverlayColorTarget) return;

    const overlay = this.imageOverlayTarget;
    const overlayOpacity = this.overlayOpacityTarget;
    const overlayColor = this.overlayColorTarget;
    overlayOpacity.value = overlay.style.opacity;

    const rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, "0").replace("NaN", "")).join("")}`;
    const defaultColor = rgba2hex(overlay.style.backgroundColor);
    overlayColor.value = defaultColor;

    $(".background-header-color").spectrum({
      preferredFormat: "hex",
      flat: false,
      showInput: true,
      allowEmpty: false,
      color: defaultColor,
      move(color) {
        const input = $(this);
        input.val(color.toHexString());
        overlay.style.background = color.toHexString();
      },
      change(color) {
        const input = $(this);
        input.val(color.toHexString());
        overlay.style.background = color.toHexString();
      },
      hide(color) {
        const input = $(this);
        input.val(color.toHexString());
        overlay.style.background = color.toHexString();
      }
    });
  }
}
