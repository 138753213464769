/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "@hotwired/turbo-rails"
import "controllers"
import "css/vendor/sweetalert.css"
import "css/vendor/select2.css"
import "css/vendor/jquery.typeahead.css"
import "font-awesome/css/font-awesome.css"
import "css/application.css"

/*
  Make Turbo Drive opt-in by default
  Add data-turbo="true" to enable Drive on a per-element basis.
  https://turbo.hotwired.dev/handbook/drive#disabling-turbo-drive-on-specific-links-or-forms
*/
Turbo.session.drive = false

/*
 * Override turbo-confirm to display a custom dialog
 */
Turbo.setConfirmMethod((message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  dialog.querySelector("p").textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue == "confirm")
    }, { once: true })
  })
})
