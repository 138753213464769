import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--events--edit--custom-field-form-panel"
export default class extends Controller {
  static targets = ["reusableFieldForm", "customFieldForm", "reusableFieldButton", "customFieldButton"];

  connect() {
  }

  toggleForm(event) {
    if (event.target.classList.contains("btn-secondary")) {
      this.reusableFieldFormTarget.classList.toggle("hidden");
      this.customFieldFormTarget.classList.toggle("hidden");
      this.reusableFieldButtonTarget.classList.toggle("btn-primary");
      this.reusableFieldButtonTarget.classList.toggle("btn-secondary");
      this.customFieldButtonTarget.classList.toggle("btn-primary");
      this.customFieldButtonTarget.classList.toggle("btn-secondary");
    }
  }
}
