import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

// Connects to data-controller="fluid--html-tooltip"
export default class extends Controller {
  connect() {
    tippy(this.element, {
      content: this.htmlContent,
      allowHTML: true,
      placement: this.placement,
      interactive: this.interactive,
      maxWidth: 425,
    });
  }

  get htmlContent() {
    const id = this.data.get("content-id");
    return document.getElementById(id).innerHTML;
  }

  get placement() {
    return this.data.get("placement") || "top";
  }

  get interactive() {
    return this.data.get("interactive") || true;
  }
}
