import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--administrators--tiered-campaigns"
export default class extends Controller {
  static targets = ["campaignSelectForm", "tieredCampaignsForm", "campaignSelect", "submitButton"];

  connect() {
  }

  change() {
    this.campaignSelectFormTarget.requestSubmit();
  }

  tieredCampaignListChange() {
    const checkboxes = this.tieredCampaignsFormTarget.querySelectorAll("input[type=\"checkbox\"]");
    const isAnyCheckboxChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);

    if (isAnyCheckboxChecked) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  handleSubmit() {
    this.campaignSelectTarget.selectedIndex = 0;
    this.change();
  }
}
