import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='categories--admin--review-gifts--error-panel'
export default class extends Controller {
  static targets = ["errorPanel", "expandedIcon", "collapsedIcon"];

  toggle() {
    const panelIsVisible = this.errorPanelTarget.classList.toggle("hidden");
    if (panelIsVisible) {
      this.expandedIconTarget.style.display = "block";
      this.collapsedIconTarget.style.display = "none";
    } else {
      this.collapsedIconTarget.style.display = "block";
      this.expandedIconTarget.style.display = "none";
    }
  }
}
