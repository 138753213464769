import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="categories--admin--settings--form-fields--add-to-forms-panel"
export default class extends Controller {
  static targets = [
    "searchInput",
    "resetButton",
    "selectAllCheckbox",
    "fieldCheckbox",
    "addToPrePaymentButton",
    "addToPostPaymentButton",
  ];

  connect() {
    this.toggleResetButton();
    this.populateTable();
  }

  load(event) {
    this.field = event.detail.field;
    this.selectedForms = [];
    this.formType = "";
    this.setPanelSubtitle();
    this.toggleButtons();
  }

  unload() {
    this.field = {};
    this.selectedForms = [];
    this.toggleButtons();
  }

  setPanelSubtitle() {
    document.querySelector(
      "#add-field-panel .fluid-modal-subtitle",
    ).textContent = this.field.title;
  }

  search(event) {
    event.preventDefault();
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();
    const rows = this.element.querySelectorAll("#add-to-forms-table tr");

    this.toggleResetButton();

    rows.forEach((row) => {
      const rowText = row.innerText.toLowerCase();
      row.classList.toggle("hidden", !rowText.includes(searchTerm));
    });
  }

  resetSearch(event) {
    event.preventDefault();
    this.searchInputTarget.value = "";
    this.searchInputTarget.focus();
    this.toggleResetButton();
    this.search(event);
  }

  toggleResetButton() {
    this.resetButtonTarget.classList.toggle(
      "hidden",
      this.searchInputTarget.value.trim() === "",
    );
  }

  selectAllForms(event) {
    const { checked } = event.target;
    this.selectedForms = checked
      ? this.fieldCheckboxTargets.map((checkbox) => Number(checkbox.value))
      : [];

    this.fieldCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });

    this.toggleButtons();
  }

  selectForm(event) {
    const checkbox = event.target;
    const selectedForms = new Set(this.selectedForms);
    const values = checkbox.value.split(",").map(Number);

    values.forEach((value) => {
      if (checkbox.checked) {
        selectedForms.add(value);
      } else {
        selectedForms.delete(value);
      }
    });

    this.selectedForms = Array.from(selectedForms);
    this.toggleButtons();
  }

  toggleButtons() {
    const hasSelectedForms = this.selectedForms.length > 0;
    this.addToPrePaymentButtonTarget.toggleAttribute(
      "disabled",
      !hasSelectedForms,
    );
    this.addToPostPaymentButtonTarget.toggleAttribute(
      "disabled",
      !hasSelectedForms,
    );
  }

  populateTable() {
    const projects = JSON.parse(
      document.getElementById("form-fields").dataset.projects,
    );
    const table = document.getElementById("add-to-forms-table");

    table.innerHTML = "";
    projects.forEach((project) => {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td class="text-center">
          <input type="checkbox" name="project_ids[]" value="${project.id}" aria-label="Select ${project.name}"
           data-action="click->categories--admin--settings--form-fields--add-field-panel#selectForm"
           data-categories--admin--settings--form-fields--add-field-panel-target="fieldCheckbox">
        </td>
        <td><a href="${project.url}" target="_blank">${project.name}</a></td>
        <td>${project.state}</td>
      `;
      table.appendChild(row);
    });
  }

  addField(event) {
    const { schoolSlug } = event.target.dataset;
    this.formType = event.target.dataset.formType;
    // TODO: Update with school
    fetch(`/schools/${schoolSlug}/admin/settings/form-fields`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({
        field: {
          addtnl_field_ids: this.field.ids,
          form_type: this.formType,
          selected_forms: this.selectedForms,
        }
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.cancel(event);
        window.location.reload();
      });
  }

  cancel(event) {
    event.preventDefault();

    this.selectedForms = [];
    this.searchInputTarget.value = "";
    this.toggleButtons();
    this.selectAllCheckboxTarget.checked = false;
    this.fieldCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });

    document.querySelectorAll(".fluid-modal-container").forEach((modal) => {
      modal.classList.add("hidden");
    });
  }
}
